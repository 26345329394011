// src/navigation/AppRouter.js

import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { auth, db } from "../firebase/firebaseConfig";
import { FirestoreContextProvider } from "../firebase/FirestoreContext";
import { onAuthStateChanged } from "firebase/auth";

// Screens
import MarketingPage from "../screens/marketing-page/MarketingPage";
import SignIn from "../screens/auth/sign-in/SignIn";
import GetStarted from "../screens/create-account/GetStarted";

const AppRouter = () => {
  const [user, setUser] = useState(null); // State to manage the authenticated user
  const [loading, setLoading] = useState(true); // State to handle loading state
  const [isDevMode, setIsDevMode] = useState(false); // State to manage development mode

  useEffect(() => {
    // Listen to the authentication state change
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      setLoading(false); // Stop loading when the auth state is determined
    });

    // Check if the app is in development mode
    if (window.location.hostname.startsWith("dev")) {
      setIsDevMode(true);
    }

    return () => unsubscribe(); // Cleanup subscription on unmount
  }, []);

  if (loading) {
    // Show a loading indicator while waiting for auth state
    return <div>Loading...</div>;
  }

  return (
    <FirestoreContextProvider db={db}>
      {/* Wrap the Router with FirestoreContextProvider */}
      <Router>
        <Routes>
          {/* Main shop page */}
          <Route path="/" element={<MarketingPage />} />
          <Route path="/signin" element={<SignIn />} />
          {/* Create account - wizard */}
          <Route path="/create-account" element={<GetStarted />} />
        </Routes>
      </Router>
    </FirestoreContextProvider>
  );
};

export default AppRouter;
