// src/App.js

import React from "react";
import AppRouter from "./navigation/AppRouter";
import "./styles/global.css";

const App = () => {
  return <AppRouter />;
};

export default App;
