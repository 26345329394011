import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/system";
import Container from "@mui/material/Container";

const logos = ["./brandLogos/mamas-food.jpg"];

const logoStyle = {
  width: "100px",
  height: "100px",
  margin: "0 35px",
  opacity: 1,
};

export default function LogoCollection() {
  const theme = useTheme();

  return (
    <Box id="logoCollection" sx={{ padding: "0px 0px" }}>
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          border: "1px solid rgba(46, 49, 45, 0.10)",
          borderTop: 0,
          borderBottom: 0,
          padding: "30px 0 50px 0",
        }}
      >
        <Grid container sx={{ justifyContent: "center", mt: 0.5, opacity: 0.6 }}>
          {logos.map((logo, index) => (
            <Grid item key={index}>
              <img src={logo} alt={`Fake company number ${index + 1}`} style={logoStyle} />
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}
