import React, { useState, useEffect, useRef } from "react";
import { scroller } from "react-scroll"; // Import scroller for smooth scrolling
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Chip from "@mui/material/Chip";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid2";
import Typography from "@mui/material/Typography";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";

const planData = {
  monthly: [
    {
      title: "Essentials",
      price: 99,
      description: ["All features included", "1 terminal device", "Up to 10 menus in archive", "Email & ticketing support"],
      buttonText: "Start now",
      buttonVariant: "contained",
      buttonColor: "primary",
    },
    {
      title: "Pro Chef",
      subheader: "Popular",
      price: 169,
      description: ["All from Essentials", "Up to 3 terminal devices", "1 POS Viewer", "Up to 25 menus in archive", "Advanced logs"],
      buttonText: "Start now",
      buttonVariant: "contained",
      buttonColor: "secondary",
    },
    {
      title: "Enterprise",
      price: "Contact Us",
      description: ["For POS Providers or restaurants with 5+ locations", "Phone & email support", "API access"],
      buttonText: "Contact us",
      buttonVariant: "contained",
      buttonColor: "primary",
    },
  ],
  yearly: [
    {
      title: "Essentials",
      price: Math.floor(99 * 0.75), // 25% discount
      description: ["All features included", "1 terminal device", "Up to 10 menus in archive", "Email & ticketing support"],
      buttonText: "Start now",
      buttonVariant: "contained",
      buttonColor: "primary",
    },
    {
      title: "Pro Chef",
      subheader: "Popular",
      price: Math.floor(169 * 0.75), // 25% discount
      description: ["All from Essentials", "Up to 3 terminal devices", "1 POS Viewer", "Up to 25 menus in archive", "Advanced logs"],
      buttonText: "Start now",
      buttonVariant: "contained",
      buttonColor: "secondary",
    },
    {
      title: "Enterprise",
      price: "Contact Us",
      description: ["For POS Providers or restaurants with 5+ locations", "Phone & email support", "API access"],
      buttonText: "Contact us",
      buttonVariant: "outlined",
      buttonColor: "primary",
    },
  ],
};

export default function Pricing({ onSelectPlan }) {
  // Accept onSelectPlan as a prop
  const [billingCycle, setBillingCycle] = useState("monthly");
  const tiers = planData[billingCycle];
  const canvasRef = useRef(null);

  // Function to toggle between monthly and yearly pricing
  const toggleBilling = () => {
    setBillingCycle(billingCycle === "monthly" ? "yearly" : "monthly");
  };

  // Function to handle plan selection and scroll to form
  const handleSelectPlan = (planTitle) => {
    onSelectPlan(planTitle, billingCycle);
    scroller.scrollTo("register-form", {
      duration: 800,
      delay: 0,
      smooth: "easeInOutQuart",
      offset: -1000,
    });
  };
  // Add the canvas-based background animation
  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");

    let width, height, particleCount;
    const particles = [];

    const colors = ["rgba(255,255,255,0.7)", "rgba(0,255,255,0.7)", "rgba(0,255,0,0.7)", "rgba(255,0,255,0.7)", "rgba(255,255,0,0.7)", "rgba(218, 225, 33,1)"];

    const resizeCanvas = () => {
      width = window.innerWidth;
      height = window.innerHeight;
      canvas.width = width;
      canvas.height = height;
      particleCount = Math.floor(width / 8);
      particles.length = 0; // Clear particles on resize

      for (let i = 0; i < particleCount; i++) {
        particles.push({
          x: Math.random() * width,
          y: Math.random() * height,
          vx: (Math.random() - 0.5) * 2,
          vy: (Math.random() - 0.5) * 2,
          size: Math.random() * 2 + 2,
          color: colors[Math.floor(Math.random() * colors.length)],
        });
      }
    };

    const drawParticles = () => {
      ctx.clearRect(0, 0, width, height);
      particles.forEach((p) => {
        ctx.beginPath();
        ctx.arc(p.x, p.y, p.size, 0, Math.PI * 2);
        ctx.fillStyle = p.color;
        ctx.fill();

        p.x += p.vx;
        p.y += p.vy;

        // Rebound from edges
        if (p.x < 0 || p.x > width) p.vx *= -1;
        if (p.y < 0 || p.y > height) p.vy *= -1;
      });
    };

    const animateParticles = () => {
      drawParticles();
      requestAnimationFrame(animateParticles);
    };

    window.addEventListener("resize", resizeCanvas);
    resizeCanvas();
    animateParticles();

    // Cleanup
    return () => {
      window.removeEventListener("resize", resizeCanvas);
      if (canvasRef.current) {
        canvasRef.current.getContext("2d").clearRect(0, 0, width, height);
      }
    };
  }, []);

  return (
    <Container
      id="pricing"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: { xs: 3, sm: 6 },
        borderLeft: "1px solid rgba(46, 49, 45, 0.10)",
        borderRight: "1px solid rgba(46, 49, 45, 0.10)",
      }}
    >
      {/* Canvas Background */}
      <canvas
        ref={canvasRef}
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          zIndex: -1,
        }}
      />

      {/* Main Content */}
      <Box
        sx={{
          width: { sm: "100%", md: "60%" },
          textAlign: "center",
        }}
      >
        <Typography component="h2" variant="h4" gutterBottom sx={{ color: "text.primary" }}>
          Plans & Pricing
        </Typography>
        <Typography variant="body1" sx={{ color: "text.secondary" }}>
          Choose the best plan for your restaurant. Start saving time and selling more.
        </Typography>

        {/* Billing Cycle Toggle */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mt: 2,
            gap: 1,
          }}
        >
          <Typography variant="body2">MONTHLY</Typography>
          <Button variant="contained" size="small" color="primary" onClick={toggleBilling} sx={{ borderRadius: "999px", px: 2 }}>
            {billingCycle === "monthly" ? "Switch to Yearly" : "Switch to Monthly"}
          </Button>
          <Typography variant="body2" sx={{ color: "primary.main", fontWeight: "bold" }}>
            SAVE 25%!
          </Typography>
        </Box>
      </Box>

      {/* Pricing Cards */}
      <Grid container spacing={3} sx={{ alignItems: "center", justifyContent: "center", width: "100%" }}>
        {tiers.map((tier, index) => (
          <Grid size={{ xs: 12, sm: 6, md: 4 }} key={tier.title}>
            <Card
              sx={{
                p: 2,
                display: "flex",
                flexDirection: "column",
                gap: 4,
                border: "1px solid #e0e0e0",
                boxShadow: 0,
                backgroundColor: index == 1 ? "rgba(183, 255, 66,.1)" : "transparent",
                transition: "transform 0.3s ease-in-out", // Add smooth transition for scaling
                "&:hover": {
                  transform: "scale(1.015)", // Slightly enlarge the card on hover
                },
              }}
            >
              <CardContent>
                <Box
                  sx={{
                    mb: 1,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: 2,
                  }}
                >
                  <Typography component="h3" variant="h6" style={{ fontWeight: "200" }}>
                    {tier.title}
                  </Typography>
                  {tier.subheader && <Chip icon={<AutoAwesomeIcon />} label={tier.subheader} />}
                </Box>
                <Box sx={{ display: "flex", alignItems: "baseline", gap: 1 }}>
                  <Typography component="h3" variant="h5" style={{ fontWeight: "400" }}>
                    {tier.price === "Contact Us" ? tier.price : `€${tier.price}`}
                  </Typography>
                  {tier.price !== "Contact Us" && (
                    <Typography component="h3" variant="h6" style={{}}>
                      /month{billingCycle === "yearly" && "*"}
                      <span style={{ fontSize: 12, fontWeight: "300", paddingLeft: 10 }}>
                        {billingCycle === "yearly" &&
                          `${`€${new Intl.NumberFormat("en-US", {
                            style: "decimal",
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }).format(tier.price * 12)}`}/year`}
                      </span>
                    </Typography>
                  )}
                </Box>
                <Divider sx={{ my: 2, opacity: 0.8, borderColor: "divider" }} />
                {tier.description.map((line) => (
                  <Box key={line} sx={{ py: 1, display: "flex", gap: 1.5, alignItems: "center" }}>
                    <CheckCircleRoundedIcon sx={{ width: 16, color: "#dae121" }} />
                    <Typography variant="subtitle2" style={{ fontWeight: "300" }}>
                      {line}
                    </Typography>
                  </Box>
                ))}
              </CardContent>

              {/* Button triggers plan selection and scrolls to form */}
              <Button
                fullWidth
                variant={tier.buttonVariant}
                color={tier.buttonColor}
                style={{
                  padding: "12px 0px",
                  backgroundColor: tier.buttonColor === "secondary" ? "#dae121" : "#e0e0e0",
                  color: "black",
                  cursor: "pointer",
                }}
                onClick={() => handleSelectPlan(tier.title)} // Call the function when a plan is clicked
              >
                {tier.buttonText}
              </Button>
            </Card>
          </Grid>
        ))}
      </Grid>

      <div style={{ display: "flex", textAlign: "left", width: "100%", opacity: 0.3, fontSize: ".8rem" }}>
        {billingCycle === "yearly" && "*25% discount applied to yearly plans. Prices shown are per month."}
      </div>
    </Container>
  );
}
