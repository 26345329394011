// src/theme/getMPTheme.js

import { createTheme } from "@mui/material/styles";

const getMPTheme = (mode) => {
  return createTheme({
    palette: {
      mode,
      primary: {
        main: "#262b27", // Replace with your primary color
      },
      secondary: {
        main: "#dae121", // Replace with your secondary color
      },
    },
    typography: {
      fontFamily: '"Poppins", "Roboto", "Helvetica", "Arial", sans-serif', // Apply the global font family
    },
    // Add other theme customizations if necessary
  });
};

export default getMPTheme;
