import React, { useState } from "react";
import {
  Box,
  Typography,
  Slider,
  Container,
  RadioGroup,
  FormControlLabel,
  Radio,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Link,
  Tooltip,
  Grow,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { styled } from "@mui/system";

const marksDeliveries = [
  { value: 0, label: "0" },
  { value: 5, label: "5" },
  { value: 10, label: "10" },
  { value: 15, label: "15" },
  { value: 20, label: "20" },
  { value: 25, label: "25" },
  { value: 30, label: "30" },
  { value: 35, label: "35" },
  { value: 40, label: "40" },
  { value: 45, label: "45" },
  { value: 50, label: "50+" },
];

const marksOrderValue = [
  { value: 5, label: "€5" },
  { value: 10, label: "€10" },
  { value: 15, label: "€15" },
  { value: 20, label: "€20" },
  { value: 25, label: "€25" },
  { value: 30, label: "€30" },
  { value: 35, label: "€35" },
  { value: 40, label: "€40" },
  { value: 45, label: "€45" },
  { value: 50, label: "€50+" },
];

const formatCurrency = (amount) => {
  return new Intl.NumberFormat("en-IE", { style: "currency", currency: "EUR" }).format(amount);
};

const CompetitorBox = styled(Box)(({ bgColor }) => ({
  backgroundColor: bgColor,
  padding: "8px 12px",
  borderRadius: "5px",
  color: "#fff",
  fontSize: "0.875rem",
  fontWeight: 300,
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: "8px",
}));

export default function CostCalculator() {
  const [deliveries, setDeliveries] = useState(5);
  const [orderValue, setOrderValue] = useState(25);
  const [packageCost, setPackageCost] = useState(99); // 99 EUR or 169 EUR based on slider

  const totalOrderValue = deliveries * orderValue * 30; // Calculate monthly total

  const competitor1Cost = totalOrderValue * 0.15;
  const competitor2Cost = totalOrderValue * 0.2;
  const competitor3Cost = totalOrderValue * 0.3;
  const competitor4Cost = totalOrderValue * 0.42;

  const mealMateCost = packageCost;

  const handleDeliveryChange = (event, newValue) => {
    setDeliveries(newValue);
  };

  const handleOrderValueChange = (event, newValue) => {
    setOrderValue(newValue);
  };

  const handlePackageChange = (event, newValue) => {
    setPackageCost(newValue);
  };

  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const renderCompetitorBox = (label, cost, bgColor) => {
    const priceDifference = cost - mealMateCost;

    return (
      <Grow in={true} timeout={1000}>
        <Tooltip title={priceDifference > 0 ? `${formatCurrency(priceDifference)} / month more than MealMate!` : ""} placement="top" arrow>
          <CompetitorBox bgColor={bgColor} sx={{ color: "#000" }}>
            <div style={{ color: "black", flex: 1 }}>{label}</div>
            <div style={{ color: "black", width: "150px", textAlign: "right" }}>{formatCurrency(cost)} / month</div>
          </CompetitorBox>
        </Tooltip>
      </Grow>
    );
  };

  return (
    <>
      <Box
        id="highlights"
        sx={{
          position: "relative",
          backgroundColor: "#fff",
          margin: "0px",
          padding: "0",
          backgroundColor: "#fafafa",
        }}
      >
        <Container
          id="compare-costs"
          sx={{
            width: "100%",
            maxWidth: "400px",
            justifyContent: "center",
            display: "flex",
            flexDirection: "column",
            borderLeft: "1px solid rgba(0,0,0,.1)",
            borderRight: "1px solid rgba(0,0,0,.1)",
            padding: "80px 0 20px 0",
          }}
        >
          <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
            <div style={{ width: "600px", padding: "0px 50px 0 20px" }}>
              <div variant="body-2" style={{ height: "50px" }}>
                Compare Pricing Plans
              </div>

              <Box sx={{ mb: 3 }}>
                <Typography sx={{ fontSize: "0.9rem", fontWeight: "300", mb: 1, textAlign: "left" }}>Number of Deliveries per Day</Typography>
                <Slider
                  value={deliveries}
                  onChange={handleDeliveryChange}
                  step={5}
                  marks={marksDeliveries}
                  min={0}
                  max={50}
                  valueLabelDisplay="auto"
                  sx={{
                    color: "#dae121",
                    height: 2,
                    "& .MuiSlider-markLabel": {
                      fontSize: "0.7rem",
                      fontWeight: 300,
                    },
                  }}
                />
              </Box>

              <Box sx={{ mb: 3 }}>
                <Typography sx={{ fontSize: "0.9rem", fontWeight: "300", mb: 1, textAlign: "left" }}>Average Order Value</Typography>
                <Slider
                  value={orderValue}
                  onChange={handleOrderValueChange}
                  step={5}
                  marks={marksOrderValue}
                  min={5}
                  max={50}
                  valueLabelDisplay="auto"
                  sx={{
                    color: "#dae121",
                    height: 2,
                    "& .MuiSlider-markLabel": {
                      fontSize: "0.7rem",
                      fontWeight: 300,
                    },
                  }}
                />
              </Box>

              <Box sx={{ mb: 3 }}>
                <Typography sx={{ fontSize: "0.9rem", fontWeight: "300", mb: 1, textAlign: "left" }}>Select MealMate Package</Typography>
                <RadioGroup value={packageCost} onChange={handlePackageChange} row>
                  <FormControlLabel
                    value={99}
                    control={<Radio sx={{ color: "#dae121" }} />}
                    label="€99 Basic"
                    sx={{ "& .MuiFormControlLabel-label": { fontSize: "0.7rem", fontWeight: 400 } }}
                  />
                  <FormControlLabel
                    value={169}
                    control={<Radio sx={{ color: "#dae121" }} />}
                    label="€169 Premium"
                    sx={{ "& .MuiFormControlLabel-label": { fontSize: "0.7rem", fontWeight: 400 } }}
                  />
                </RadioGroup>
              </Box>
            </div>

            <div style={{ flex: 1 }}>
              <div variant="body-2" style={{ height: "50px" }}>
                MealMate Cost Comparison VS percent per transaction
              </div>

              {/* Competitor costs with animation and tooltips */}
              {renderCompetitorBox("Competitor with 15% per order", competitor1Cost, "#C7CACA")}
              {renderCompetitorBox("Competitor with 20% per order", competitor2Cost, "#D8CECD")}
              {renderCompetitorBox("Competitor with 30% per order", competitor3Cost, "#E9D3D1")}
              {renderCompetitorBox("Competitor with 42% per order", competitor4Cost, "#FFD8D6")}

              <CompetitorBox bgColor="#dae121" sx={{ color: "#000", fontSize: "1rem", fontWeight: "500" }}>
                <div style={{ color: "black", flex: 1, fontFamily: "Mogra", fontSize: "1.2rem", padding: "2px 0 0 0" }}>MealMate</div>
                <div style={{ color: "black", width: "150px", textAlign: "right" }}>{formatCurrency(mealMateCost)} / month</div>
              </CompetitorBox>
              <div style={{ fontSize: ".7rem", opacity: 0.5 }}>
                * The average food delivery order value in Western Europe typically ranges from <b>€20 to €30</b>, varying by country and type of meal. Percent
                of pickup orders is typically 20% to 30% depending of urban density.
              </div>
            </div>
          </div>
        </Container>
      </Box>

      {/* FAQ Section */}
      <Box
        id="highlights"
        sx={{
          position: "relative",
          backgroundColor: "#fff",
          margin: "0px",
        }}
      >
        <Container
          id="compare-costs"
          sx={{
            width: "100%",
            justifyContent: "center",
            display: "flex",
            flexDirection: "column",
            borderLeft: "1px solid rgba(0,0,0,.1)",
            borderRight: "1px solid rgba(0,0,0,.1)",
            padding: "0px 0 20px 0",
          }}
        >
          <Box
            id="faq"
            sx={{
              width: { sm: "100%", md: "100%", marginTop: "50px" },
              textAlign: "center",
            }}
          >
            <Typography component="h2" variant="h4" gutterBottom sx={{ color: "text.primary" }}>
              FAQ
            </Typography>
            <div variant="body-2" style={{ fontSize: ".8rem", paddingBottom: "30px" }}>
              Find answers to common questions about getting started with MealMate, activating your account, choosing the right package, and more.
            </div>

            <Accordion expanded={expanded === "panel1"} onChange={handleChange("panel1")}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1d-content" id="panel1d-header">
                <Typography component="h3" variant="subtitle2">
                  Can I try MealMate before paying?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div variant="body-2" style={{ textAlign: "left", fontSize: ".8rem" }}>
                  Yes, you can fully test MealMate by setting up a test paydesk, creating your menu, connecting it with the app, and placing test orders using a
                  simulated payment environment. This allows you to experience the platform as both a restaurant and a user before subscribing.
                </div>
              </AccordionDetails>
            </Accordion>

            <Accordion expanded={expanded === "panel2"} onChange={handleChange("panel2")}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2d-content" id="panel2d-header">
                <Typography component="h3" variant="subtitle2">
                  How long does it take to activate a test account?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div variant="body-2" style={{ textAlign: "left", fontSize: ".8rem" }}>
                  Activation is immediate. Simply create your account and follow a few simple steps.
                </div>
              </AccordionDetails>
            </Accordion>

            <Accordion expanded={expanded === "panel3"} onChange={handleChange("panel3")}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel3d-content" id="panel3d-header">
                <Typography component="h3" variant="subtitle2">
                  Which package is best for my business?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div variant="body-2" style={{ textAlign: "left", fontSize: ".8rem" }}>
                  Essentials and Pro Chef offer all the tools you need for regular business. Pro Chef includes all current features, plus any future innovations
                  to help you stay ahead in the market.
                </div>
              </AccordionDetails>
            </Accordion>

            <Accordion expanded={expanded === "panel4"} onChange={handleChange("panel4")}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel4d-content" id="panel4d-header">
                <Typography component="h3" variant="subtitle2">
                  What do I need to do to activate the production version?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div variant="body-2" style={{ textAlign: "left", fontSize: ".8rem" }}>
                  Once you’re familiar with MealMate, simply activate the production version, create your menu, and connect with customers. We will review and
                  approve your first menu release before it goes live.
                </div>
              </AccordionDetails>
            </Accordion>

            <Accordion expanded={expanded === "panel5"} onChange={handleChange("panel5")}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel5d-content" id="panel5d-header">
                <Typography component="h3" variant="subtitle2">
                  Are there any restrictions on accepting restaurants?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div variant="body-2" style={{ textAlign: "left", fontSize: ".8rem" }}>
                  Yes, MealMate is focused on quality food and customer satisfaction. We welcome high-quality fast food but will reject restaurants with poor
                  ratings or feedback, with the option of a refund based on remaining subscription time.
                </div>
              </AccordionDetails>
            </Accordion>

            <Accordion expanded={expanded === "panel6"} onChange={handleChange("panel6")}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel6d-content" id="panel6d-header">
                <Typography component="h3" variant="subtitle2">
                  I’m a POS provider and interested in cooperation.
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div variant="body-2" style={{ textAlign: "left", fontSize: ".8rem" }}>
                  Please email us at <Link href="mailto:posproviders@mealmate.be">posproviders@mealmate.be</Link> with details about your company, clients, and
                  APIs. We’ll be happy to explore potential partnerships.
                </div>
              </AccordionDetails>
            </Accordion>

            {/* Other FAQs */}
            {/* Repeat Accordion structure for other FAQ items */}
          </Box>
        </Container>
      </Box>
    </>
  );
}
