import * as React from "react";
import { useInView } from "react-intersection-observer";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid2";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import MenuBookRoundedIcon from "@mui/icons-material/MenuBookRounded";
import PrecisionManufacturingRoundedIcon from "@mui/icons-material/PrecisionManufacturingRounded";
import DeliveryDining from "@mui/icons-material/DeliveryDining";
import RedeemRoundedIcon from "@mui/icons-material/RedeemRounded";
import QueryStatsRoundedIcon from "@mui/icons-material/QueryStatsRounded";
import AccessTimeRoundedIcon from "@mui/icons-material/AccessTimeRounded";
import PauseCircleRoundedIcon from "@mui/icons-material/PauseCircleRounded";
import IntegrationInstructionsRoundedIcon from "@mui/icons-material/IntegrationInstructionsRounded";
import DevicesOtherRoundedIcon from "@mui/icons-material/DevicesOtherRounded";
import PaidRoundedIcon from "@mui/icons-material/PaidRounded";
import AutoFixHighRoundedIcon from "@mui/icons-material/AutoFixHighRounded";
import SupportAgentRoundedIcon from "@mui/icons-material/SupportAgentRounded";
import Container from "@mui/material/Container";
import CheckCircleTwoTone from "@mui/icons-material/CheckCircleTwoTone";

// Enhanced content for features with variation
const items = [
  {
    icon: <MenuBookRoundedIcon sx={{ fontSize: 14, color: "silver" }} />,
    title: "Menu Management",
    description:
      "Create menus quickly and easily with flexible options for daily specials, combo offers, and more. Keep your offerings up-to-date and aligned with your customers’ needs with live updates. Manage everything from pricing to availability effortlessly.",
    keys: ["Daily Specials", "Combo Offers", "Live Updates", "Pricing Control", "Custom Attributes"],
  },
  {
    icon: <PrecisionManufacturingRoundedIcon sx={{ fontSize: 14, color: "silver" }} />,
    title: "Menu Builder",
    description:
      "Drag-and-drop your menu to customize items. Highlight popular dishes and organize your menu in a way that increases customer engagement. It’s built for ease and flexibility.",
    keys: ["Drag & Drop", "Highlight Popular Items"],
  },
  {
    icon: <DeliveryDining sx={{ fontSize: 14, color: "silver" }} />,
    title: "Delivery & Pickup",
    description:
      "Offer both delivery and pickup with real-time tracking. Manage orders from the kitchen to the customer's door, ensuring transparency and speed. Optimize your delivery process to handle both in-house and third-party couriers effectively.",
    keys: ["Real-Time Tracking", "Pickup Options", "Third-Party Delivery"],
  },
  {
    icon: <RedeemRoundedIcon sx={{ fontSize: 14, color: "silver" }} />,
    title: "Loyalty Programs",
    description:
      "Increase customer retention by rewarding repeat visits. Customize loyalty programs to fit your restaurant’s brand and watch as customers come back for more. Personalized rewards keep your audience engaged.",
    keys: ["Custom Rewards", "Repeat Customers", "Retention Tools"],
  },
  {
    icon: <QueryStatsRoundedIcon sx={{ fontSize: 14, color: "silver" }} />,
    title: "Real-Time Stats",
    description:
      "Track sales, delivery performance, and customer preferences in real time. Gain valuable insights to adjust your operations and menu offerings. Use data to make smarter business decisions and improve efficiency.",
    keys: ["Sales Tracking", "Performance Insights"],
  },
  {
    icon: <AccessTimeRoundedIcon sx={{ fontSize: 14, color: "silver" }} />,
    title: "Time Control",
    description:
      "Manage your restaurant’s hours, preparation times, and special holiday schedules. Stay in control of your kitchen’s timing with flexible working hours and preparation time settings.",
    keys: ["Prep Times", "Flexible Hours"],
  },
  {
    icon: <PauseCircleRoundedIcon sx={{ fontSize: 14, color: "silver" }} />,
    title: "Order Pause",
    description:
      "Take a break from incoming orders during peak times or for other operational needs. Resume order-taking with just one click, ensuring a smoother workflow.",
    keys: ["Pause Orders", "Peak Control"],
  },
  {
    icon: <IntegrationInstructionsRoundedIcon sx={{ fontSize: 14, color: "silver" }} />,
    title: "POS Integration",
    description:
      "Integrate seamlessly with your existing POS system. Sync orders, payments, and menu changes across all platforms. This ensures smoother operations and less manual work for your team.",
    keys: ["POS Sync", "Payments", "Order Management"],
  },
  {
    icon: <DevicesOtherRoundedIcon sx={{ fontSize: 14, color: "silver" }} />,
    title: "POS Control",
    description:
      "Control your entire system from the POS. Make updates to your menu or orders from one central platform. This integration makes it easier to manage all aspects of your business in real time.",
    keys: ["Full POS Control", "Real-Time Sync"],
  },
  {
    icon: <PaidRoundedIcon sx={{ fontSize: 14, color: "silver" }} />,
    title: "Flexible Payments",
    description:
      "Offer multiple payment options to your customers, whether it's instant payments, delayed, or split payments. Integrate with popular gateways for smooth, secure transactions.",
    keys: ["Multiple Payment Methods", "Gateways"],
  },
  {
    icon: <AutoFixHighRoundedIcon sx={{ fontSize: 14, color: "silver" }} />,
    title: "Continuous Updates",
    description:
      "Our system is constantly evolving to meet your restaurant's growing needs. Expect new features and updates regularly, keeping you ahead of industry trends. We listen to your feedback and ensure the platform grows with you.",
    keys: ["New Features", "User Feedback"],
  },
  {
    icon: <SupportAgentRoundedIcon sx={{ fontSize: 14, color: "silver" }} />,
    title: "24/7 Support",
    description:
      "Our support team is available 24/7 to assist with any technical issues. Whether it’s maintenance or troubleshooting, you can rely on us to keep your restaurant running smoothly at all times.",
    keys: ["24/7 Support", "Maintenance", "Technical Assistance"],
  },
];

export default function Highlights() {
  return (
    <Box
      id="highlights"
      sx={{
        position: "relative",
        backgroundColor: "#fff",
        margin: "0px",
        padding: "0",
        borderTop: "1px solid rgba(0, 0, 0, 0.1)",
        backgroundColor: "#fafafa",
      }}
    >
      <Container sx={{ borderLeft: "1px solid rgba(0, 0, 0, 0.1)", borderRight: "1px solid rgba(0, 0, 0, 0.1)" }}>
        <Box
          sx={{
            width: {},
            textAlign: "center",
            padding: "100px 0 40px 0",
          }}
        >
          <Typography component="h2" variant="h4" gutterBottom sx={{ color: "text.primary" }}>
            Why Choose Our Platform?
          </Typography>
          <Typography variant="body1" sx={{ color: "text.secondary" }}>
            Powerful features. Simplified operations.
          </Typography>
        </Box>

        {/* Grid of features */}
        <Grid container>
          {items.map((item, index) => (
            <FeatureCard key={index} item={item} />
          ))}
        </Grid>
      </Container>
    </Box>
  );
}

// FeatureCard component with scroll animation
function FeatureCard({ item }) {
  const { ref, inView } = useInView({
    threshold: 0.2, // Trigger animation when 20% of the element is in view
    triggerOnce: true, // Only trigger animation once
  });

  return (
    <Grid item xs={12} sm={6} md={4}>
      <Stack
        direction="row"
        spacing={2}
        ref={ref}
        className={`fade-in ${inView ? "visible" : ""}`} // Apply fade-in animation
        sx={{
          alignItems: "center",
          display: "flex",
          flexDirection: "row",
          marginBottom: "40px",
        }}
      >
        <Box style={{ position: "relative", flex: 1, paddingRight: "50px" }}>
          <div style={{ background: "#dae121", position: "absolute", height: "20px", width: "3px", left: "-26px" }} />
          <Typography variant="body1" sx={{ fontWeight: "400", color: "text.primary", display: "flex", lineHeight: 1.8, fontSize: ".9rem" }}>
            <Box sx={{ marginRight: "10px", alignSelf: "center", display: "flex" }}>{item.icon}</Box>
            {item.title}
          </Typography>
        </Box>
        <Box style={{ flex: 2 }}>
          <Typography variant="body1" sx={{ color: "text.secondary", lineHeight: 1.8, fontWeight: "300", fontSize: ".9rem" }}>
            {item.description}
          </Typography>
        </Box>
        <Box style={{ flex: 1, paddingLeft: "50px" }}>
          <Typography variant="body1" sx={{ color: "text.secondary", lineHeight: 1.8, fontWeight: "300", fontSize: ".9rem" }}>
            {item.keys.map((key, idx) => (
              <div key={idx} style={{ alignItems: "center", display: "flex" }}>
                <CheckCircleTwoTone style={{ fontSize: 14, marginRight: 10, color: "#5b7f23" }} />
                {key}
              </div>
            ))}
          </Typography>
        </Box>
      </Stack>
    </Grid>
  );
}
