import React, { useState, useEffect, useRef } from "react";
import { Box, Container, Typography } from "@mui/material";
import { useInView } from "react-intersection-observer";
import FeatureSvg_01 from "./Features/FeatureSvg_01";
import FeatureSvg_02 from "./Features/FeatureSvg_02";
import FeatureSvg_03 from "./Features/FeatureSvg_03";
import FeatureSvg_04 from "./Features/FeatureSvg_04";

const items = [
  {
    title: "Mobile App – Seamless Food Ordering for End Users",
    description:
      "Our user-friendly MealMate Mobile App makes food ordering a breeze. Whether you're craving your favorite dish or exploring new restaurants, customers can easily browse, customize, and place orders with just a few taps.",
    svg: 1,
  },
  {
    title: "CMS – Control Every Aspect of Your Business",
    description:
      "The MealMate CMS is your all-in-one control system. Designed for POS providers and restaurants, it offers full management of menus, orders, and customer insights.",
    svg: 2,
  },
  {
    title: "Terminal – Fast and Efficient Order Management",
    description: "Our Terminal is a tablet-based tool that brings speed and simplicity to order management.",
    svg: 3,
  },
  {
    title: "Kitchen Display System – Streamline Order Processing",
    description:
      "Our Kitchen Display System is a digital order management tool that helps restaurants streamline order processing and improve kitchen efficiency.",
    svg: 4,
  },
];

export default function Features() {
  const [activeSvg, setActiveSvg] = useState(1);
  const [opacity, setOpacity] = useState(1);
  const [scale, setScale] = useState(1); // State to handle zoom effect
  const refs = useRef([]);

  // Declare useInView hooks outside of the loop
  const { ref: ref1, inView: inView1 } = useInView({ threshold: 0.6 });
  const { ref: ref2, inView: inView2 } = useInView({ threshold: 0.6 });
  const { ref: ref3, inView: inView3 } = useInView({ threshold: 0.6 });
  const { ref: ref4, inView: inView4 } = useInView({ threshold: 0.6 });

  // Array of refs created for easy mapping
  const inViewRefs = [ref1, ref2, ref3, ref4];

  useEffect(() => {
    if (inView1) {
      switchSvg(1);
    } else if (inView2) {
      switchSvg(2);
    } else if (inView3) {
      switchSvg(3);
    } else if (inView4) {
      switchSvg(4);
    }
  }, [inView1, inView2, inView3, inView4]);

  const switchSvg = (newSvg) => {
    if (newSvg === activeSvg) return;

    setTimeout(() => {
      setOpacity(0); // Start fade-out
      setScale(1.15); // Start zoom-out
      // Change the SVG component
      setTimeout(() => {
        setActiveSvg(newSvg);
        setOpacity(1); // Fade-in after the SVG change
        setScale(1); // Zoom-in after the SVG change
      }, 220); // Small delay before starting fade-in and zoom-in to make the transition smooth
    }, 0); // Delay to allow the fade-out and zoom-out effect before changing the SVG
  };

  return (
    <Box
      id="highlights"
      sx={{
        position: "relative",
        backgroundColor: "#fff",
        margin: "0px",
        padding: "0",
        borderTop: ".5px dashed rgba(0, 0, 0, 0.15)",
        backgroundColor: "#fafafa",
      }}
    >
      <Container
        id="features"
        sx={{
          display: "flex",
          maxWidth: "lg",
          padding: "0px !important",
          borderLeft: "1px solid rgba(0, 0, 0, 0.07)",
          borderRight: "1px solid rgba(0, 0, 0, 0.07)",
        }}
      >
        {/* Scrollable Text Section */}
        <Box
          sx={{
            width: { xs: "100%", md: "50%" },
          }}
        >
          {items.map((item, index) => (
            <Box
              key={index}
              ref={inViewRefs[index]} // Assigning the correct ref to each item
              sx={{
                height: "50vh",
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                padding: "20px",
              }}
            >
              <Typography variant="h3" sx={{ fontWeight: "bold", mb: 2, fontWeight: "500", fontSize: "1.8rem" }}>
                {item.title}
              </Typography>
              <Typography variant="body1" style={{ lineHeight: "28px", fontSize: "1rem", opacity: 0.7 }}>
                {item.description}
              </Typography>
            </Box>
          ))}
        </Box>

        {/* Fixed Image Section */}
        <Box
          sx={{
            width: "50%",
            display: { xs: "none", md: "block" },
            position: "sticky",
            top: 0,
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            padding: "20px ",
          }}
        >
          <Box
            sx={{
              aspectRatio: 1,
              width: "100%",
              transition: "opacity .3s ease-in-out, transform .4s ease-in-out",
              position: "sticky",
              top: "25%",
              border: "1px dashed rgba(0, 0, 0, 0.07)",
              padding: "10px",
              opacity: opacity,
              transform: `scale(${scale})`, // Applying zoom effect
              display: "flex",
            }}
          >
            {activeSvg === 1 && <FeatureSvg_01 />}
            {activeSvg === 2 && <FeatureSvg_02 />}
            {activeSvg === 3 && <FeatureSvg_03 />}
            {activeSvg === 4 && <FeatureSvg_04 />}
          </Box>
        </Box>
      </Container>
    </Box>
  );
}
