// src/firebase/FirestoreContext.js

import React, { createContext, useContext } from "react";
import { getFirestore } from "firebase/firestore";
import { app } from "./firebaseConfig"; // Your Firebase configuration file

const FirestoreContext = createContext();

export const FirestoreContextProvider = ({ children }) => {
  const firestore = getFirestore(app); // Initialize Firestore

  return <FirestoreContext.Provider value={firestore}>{children}</FirestoreContext.Provider>;
};

export const useFirestore = () => {
  const context = useContext(FirestoreContext);
  if (context === undefined) {
    throw new Error("useFirestore must be used within a FirestoreContextProvider");
  }
  return context;
};
