import * as React from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid2";
import { useTheme } from "@mui/system";

const userTestimonials = [
  {
    avatar: <Avatar alt="Mislav Horvat" src="/static/images/avatar/1.jpg" />,
    name: "Mislav Horvat",
    occupation: "Restaurant Owner, Croatia",
    testimonial:
      "MealMate has significantly reduced our operational costs. We’ve been able to motivate our customers with free meal add-ons, which has increased orders and engagement. It's been a game changer for us in a competitive market!",
  },
  {
    avatar: <Avatar alt="Leonie De Smet" src="/static/images/avatar/2.jpg" />,
    name: "Leonie De Smet",
    occupation: "Restaurant Owner, Belgium",
    testimonial:
      "As someone who recently started my business and manages it with a small team, MealMate's simplicity has been invaluable. I don't need too many workers to manage my operations efficiently, and it has streamlined everything.",
  },
  {
    avatar: <Avatar alt="Matej Novak" src="/static/images/avatar/3.jpg" />,
    name: "Matej Novak",
    occupation: "POS Provider, Slovenia",
    testimonial:
      "Integrating MealMate into our system was incredibly easy thanks to their simple API. The process was smooth, and it has allowed us to deliver a more complete solution to our clients without the hassle of complicated setup.",
  },
];

export default function Testimonials() {
  const theme = useTheme();

  return (
    <Container
      id="testimonials"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: { xs: 3, sm: 6 },
        border: "1px solid rgba(46, 49, 45, 0.10)",
        borderTop: 0,
        borderBottom: 0,
      }}
    >
      <Grid container spacing={2}>
        {userTestimonials.map((testimonial, index) => (
          <Grid size={{ xs: 12, sm: 6, md: 4 }} key={index} sx={{ display: "flex" }}>
            <Card
              variant="outlined"
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                flexGrow: 1,
                transition: "transform 0.3s ease-in-out",
                "&:hover": {
                  transform: "scale(1.015)", // Slightly enlarge the card on hover
                },
              }}
            >
              <CardContent>
                <Typography variant="body1" gutterBottom sx={{ color: "text.secondary" }}>
                  {testimonial.testimonial}
                </Typography>
              </CardContent>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <CardHeader avatar={testimonial.avatar} title={testimonial.name} subheader={testimonial.occupation} />
              </Box>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}
