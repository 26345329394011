import React, { useState, useEffect } from "react";
import { useInView } from "react-intersection-observer";
import "./FeatureSvg_03.css"; // Optional: If you want to keep styles in a separate file

const FeatureSvg_01 = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="500" height="500" viewBox="0 0 500 500">
      <defs>
        <clipPath id="clip-Features_3">
          <rect width="500" height="500" class="svg-elem-1"></rect>
        </clipPath>
      </defs>
      <g id="Features_3" data-name="Features 3" clip-path="url(#clip-Features_3)">
        <g id="Group_5" data-name="Group 5" transform="translate(0 -6)">
          <g id="Group_3" data-name="Group 3" transform="translate(-12 5)">
            <g id="Group_2" data-name="Group 2">
              <g id="Rectangle_1" data-name="Rectangle 1" transform="translate(18 50)" fill="none" stroke="#181817" stroke-width="0.3">
                <rect width="488" height="335" rx="10" stroke="none" class="svg-elem-2"></rect>
                <rect x="0.15" y="0.15" width="487.7" height="334.7" rx="9.85" fill="none" class="svg-elem-3"></rect>
              </g>
              <line
                id="Line_1"
                data-name="Line 1"
                x2="488"
                transform="translate(18.5 341.5)"
                fill="none"
                stroke="#181817"
                stroke-width="0.3"
                class="svg-elem-4"
              ></line>
            </g>
            <g id="Group_4" data-name="Group 4">
              <path
                id="Path_1"
                data-name="Path 1"
                d="M213.508,385s-3.667,35.819-4.325,41.948.7,7.193-6.592,12.219-8.992,6.3-10.938,7.468-5.754,5.3,1.292,5.3h67.526"
                fill="none"
                stroke="#181817"
                stroke-width="0.3"
                class="svg-elem-5"
              ></path>
              <path
                id="Path_2"
                data-name="Path 2"
                d="M235.827,385s3.667,35.819,4.325,41.948-.7,7.193,6.592,12.219,8.992,6.3,10.938,7.468,5.754,5.3-1.292,5.3H185.274"
                transform="translate(75)"
                fill="none"
                stroke="#181817"
                stroke-width="0.3"
                class="svg-elem-6"
              ></path>
            </g>
          </g>
        </g>
        <line
          id="Line_9"
          data-name="Line 9"
          y2="245"
          transform="translate(84.5 86.5)"
          fill="none"
          stroke="#171717"
          stroke-width="0.2"
          opacity="0.363"
          class="svg-elem-7"
        ></line>
        <line
          id="Line_10"
          data-name="Line 10"
          x2="464"
          transform="translate(18.5 109.5)"
          fill="none"
          stroke="#171717"
          stroke-width="0.2"
          opacity="0.363"
          class="svg-elem-8"
        ></line>
        <rect id="Rectangle_62" data-name="Rectangle 62" width="24" height="2" transform="translate(91 107.5)" fill="#dae121" class="svg-elem-9"></rect>
        <g id="Rectangle_96" data-name="Rectangle 96" transform="translate(18 61)" fill="none" stroke="#313131" stroke-width="0.3">
          <rect width="464" height="270" stroke="none" class="svg-elem-10"></rect>
          <rect x="0.15" y="0.15" width="463.7" height="269.7" fill="none" class="svg-elem-11"></rect>
        </g>
        <path
          id="Path_38"
          data-name="Path 38"
          d="M1241.232,179.769c18.426.289,33.556.875,41.007,0s5.943-3.732,10.744-8.912,11.027-15.988,18.469-13.783,12.514,3.906,18.407,0,9.695-5.232,16.121-3.38,11.268,18.48,18.685,17.163,8.929-18.969,17.016-17.163,3.106,25.667,8.6,26.075,8.053.236,9.445,0"
          transform="translate(-1140)"
          fill="none"
          stroke="#dae121"
          stroke-width="0.5"
          class="svg-elem-12"
        ></path>
        <rect id="Rectangle_63" data-name="Rectangle 63" width="31" height="5" transform="translate(22 117)" fill="#e8e8e8" class="svg-elem-13"></rect>
        <rect id="Rectangle_64" data-name="Rectangle 64" width="39" height="5" transform="translate(22 124)" fill="#e8e8e8" class="svg-elem-14"></rect>
        <rect id="Rectangle_65" data-name="Rectangle 65" width="27" height="5" transform="translate(22 146)" fill="#e8e8e8" class="svg-elem-15"></rect>
        <rect id="Rectangle_66" data-name="Rectangle 66" width="33" height="5" transform="translate(22 153)" fill="#e8e8e8" class="svg-elem-16"></rect>
        <rect id="Rectangle_67" data-name="Rectangle 67" width="39" height="5" transform="translate(22 175)" fill="#e8e8e8" class="svg-elem-17"></rect>
        <rect id="Rectangle_68" data-name="Rectangle 68" width="33" height="5" transform="translate(22 182)" fill="#e8e8e8" class="svg-elem-18"></rect>
        <rect id="Rectangle_69" data-name="Rectangle 69" width="36" height="5" transform="translate(22 189)" fill="#e8e8e8" class="svg-elem-19"></rect>
        <rect id="Rectangle_70" data-name="Rectangle 70" width="42" height="5" transform="translate(22 196)" fill="#e8e8e8" class="svg-elem-20"></rect>
        <rect id="Rectangle_71" data-name="Rectangle 71" width="31" height="5" transform="translate(22 217)" fill="#e8e8e8" class="svg-elem-21"></rect>
        <rect id="Rectangle_72" data-name="Rectangle 72" width="39" height="5" transform="translate(22 224)" fill="#e8e8e8" class="svg-elem-22"></rect>
        <rect id="Rectangle_73" data-name="Rectangle 73" width="31" height="5" transform="translate(22 231)" fill="#e8e8e8" class="svg-elem-23"></rect>
        <rect id="Rectangle_74" data-name="Rectangle 74" width="39" height="5" transform="translate(22 238)" fill="#e8e8e8" class="svg-elem-24"></rect>
        <rect id="Rectangle_75" data-name="Rectangle 75" width="62" height="16" transform="translate(20 90)" fill="#e8e8e8" class="svg-elem-25"></rect>
        <line
          id="Line_11"
          data-name="Line 11"
          x2="2"
          y2="2"
          transform="translate(73.5 97.5)"
          fill="none"
          stroke="#707070"
          stroke-linecap="round"
          stroke-width="0.3"
          opacity="0.418"
          class="svg-elem-26"
        ></line>
        <line
          id="Line_12"
          data-name="Line 12"
          x1="2"
          y2="2"
          transform="translate(75.5 97.5)"
          fill="none"
          stroke="#707070"
          stroke-linecap="round"
          stroke-width="0.3"
          opacity="0.418"
          class="svg-elem-27"
        ></line>
        <g id="Ellipse_6" data-name="Ellipse 6" transform="translate(23 92)" fill="none" stroke="#707070" stroke-width="0.3" opacity="0.33">
          <circle cx="6" cy="6" r="6" stroke="none" class="svg-elem-28"></circle>
          <circle cx="6" cy="6" r="5.85" fill="none" class="svg-elem-29"></circle>
        </g>
        <rect id="Rectangle_76" data-name="Rectangle 76" width="464" height="19" transform="translate(18 68)" fill="#e8e8e8" class="svg-elem-30"></rect>
        <rect id="Rectangle_77" data-name="Rectangle 77" width="464" height="7" transform="translate(18 61)" fill="#ba4978" class="svg-elem-31"></rect>
        <rect id="Rectangle_78" data-name="Rectangle 78" width="23" height="5" transform="translate(92 99)" fill="#e8e8e8" class="svg-elem-32"></rect>
        <rect id="Rectangle_80" data-name="Rectangle 80" width="68" height="34" rx="6" transform="translate(270 148)" fill="#e8e8e8" class="svg-elem-33"></rect>
        <line
          id="Line_13"
          data-name="Line 13"
          y2="222"
          transform="translate(347.5 109.5)"
          fill="none"
          stroke="#707070"
          stroke-width="0.2"
          class="svg-elem-34"
        ></line>
        <line
          id="Line_14"
          data-name="Line 14"
          x2="160"
          transform="translate(99.5 148.5)"
          fill="none"
          stroke="#707070"
          stroke-width="0.2"
          stroke-dasharray="3 3"
          opacity="0.503"
          class="svg-elem-35"
        ></line>
        <line
          id="Line_15"
          data-name="Line 15"
          x2="160"
          transform="translate(99.5 157.5)"
          fill="none"
          stroke="#707070"
          stroke-width="0.2"
          stroke-dasharray="3 3"
          opacity="0.503"
          class="svg-elem-36"
        ></line>
        <line
          id="Line_16"
          data-name="Line 16"
          x2="160"
          transform="translate(99.5 164.5)"
          fill="none"
          stroke="#707070"
          stroke-width="0.2"
          stroke-dasharray="3 3"
          opacity="0.503"
          class="svg-elem-37"
        ></line>
        <line
          id="Line_17"
          data-name="Line 17"
          x2="160"
          transform="translate(99.5 172.5)"
          fill="none"
          stroke="#707070"
          stroke-width="0.2"
          stroke-dasharray="3 3"
          opacity="0.503"
          class="svg-elem-38"
        ></line>
        <line
          id="Line_18"
          data-name="Line 18"
          x2="160"
          transform="translate(99.5 180.5)"
          fill="none"
          stroke="#707070"
          stroke-width="0.2"
          stroke-dasharray="3 3"
          opacity="0.503"
          class="svg-elem-39"
        ></line>
        <rect id="Rectangle_81" data-name="Rectangle 81" width="16" height="5" transform="translate(90 136)" fill="#e8e8e8" class="svg-elem-40"></rect>
        <rect id="Rectangle_82" data-name="Rectangle 82" width="16" height="5" transform="translate(108 136)" fill="#e8e8e8" class="svg-elem-41"></rect>
        <rect id="Rectangle_83" data-name="Rectangle 83" width="16" height="5" transform="translate(127 136)" fill="#e8e8e8" class="svg-elem-42"></rect>
        <rect id="Rectangle_84" data-name="Rectangle 84" width="39" height="6" transform="translate(90 206)" fill="#e8e8e8" class="svg-elem-43"></rect>
        <rect id="Rectangle_85" data-name="Rectangle 85" width="172" height="4" transform="translate(90 217)" fill="#e8e8e8" class="svg-elem-44"></rect>
        <rect id="Rectangle_86" data-name="Rectangle 86" width="158" height="4" transform="translate(90 224)" fill="#e8e8e8" class="svg-elem-45"></rect>
        <rect id="Rectangle_87" data-name="Rectangle 87" width="165" height="4" transform="translate(90 231)" fill="#e8e8e8" class="svg-elem-46"></rect>
        <rect id="Rectangle_89" data-name="Rectangle 89" width="123" height="4" transform="translate(353 134)" fill="#e8e8e8" class="svg-elem-47"></rect>
        <rect id="Rectangle_90" data-name="Rectangle 90" width="109" height="4" transform="translate(353 141)" fill="#e8e8e8" class="svg-elem-48"></rect>
        <rect id="Rectangle_91" data-name="Rectangle 91" width="116" height="4" transform="translate(353 148)" fill="#e8e8e8" class="svg-elem-49"></rect>
        <rect id="Rectangle_92" data-name="Rectangle 92" width="123" height="4" transform="translate(353 155)" fill="#e8e8e8" class="svg-elem-50"></rect>
        <rect id="Rectangle_93" data-name="Rectangle 93" width="109" height="4" transform="translate(353 162)" fill="#e8e8e8" class="svg-elem-51"></rect>
        <rect id="Rectangle_94" data-name="Rectangle 94" width="116" height="4" transform="translate(353 169)" fill="#e8e8e8" class="svg-elem-52"></rect>
        <g id="Rectangle_61" data-name="Rectangle 61" transform="translate(18 61)" fill="none" stroke="#313131" stroke-width="0.3">
          <rect width="464" height="270" stroke="none" class="svg-elem-53"></rect>
          <rect x="0.15" y="0.15" width="463.7" height="269.7" fill="none" class="svg-elem-54"></rect>
        </g>
        <g id="Rectangle_95" data-name="Rectangle 95" transform="translate(353 181)" fill="#f5f5f5" stroke="#dedede" stroke-width="0.2">
          <rect width="123" height="66" rx="8" stroke="none" class="svg-elem-55"></rect>
          <rect x="0.1" y="0.1" width="122.8" height="65.8" rx="7.9" fill="none" class="svg-elem-56"></rect>
        </g>
        <path
          id="Rectangle_79"
          data-name="Rectangle 79"
          d="M29.632.068,0,0V5.14l29.632-.069Z"
          transform="translate(120.184 99.36)"
          fill="#e8e8e8"
          class="svg-elem-57"
        ></path>
        <path
          id="Rectangle_88"
          data-name="Rectangle 88"
          d="M31.251.072,0,0V6.168l31.251-.1Z"
          transform="translate(353.184 116.36)"
          fill="#e8e8e8"
          class="svg-elem-58"
        ></path>
      </g>
    </svg>
  );
};

export default FeatureSvg_01;
