import React, { useState, useEffect, useRef } from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import AppAppBar from "../../elements/header/AppAppBar";
import Hero from "./components/Hero";
import LogoCollection from "./components/LogoCollection";
import Highlights from "./components/Highlights";
import Pricing from "./components/Pricing";
import Features from "./components/Features";
import Testimonials from "./components/Testimonials";
import FAQ from "./components/FAQ";
import Footer from "./components/Footer";
import getMPTheme from "../../theme/getMPTheme";
import TemplateFrame from "./TemplateFrame";
import { scroller } from "react-scroll";

export default function MarketingPage() {
  const [mode, setMode] = React.useState("light");
  const [showCustomTheme, setShowCustomTheme] = React.useState(true);
  const [selectedPlan, setSelectedPlan] = useState("");
  const MPTheme = createTheme(getMPTheme(mode));
  const defaultTheme = createTheme({ palette: { mode } });

  // This code only runs on the client side, to determine the system color preference
  React.useEffect(() => {
    // Check if there is a preferred mode in localStorage
    const savedMode = localStorage.getItem("themeMode");
    if (savedMode) {
      setMode(savedMode);
    } else {
      // If no preference is found, it uses system preference
      const systemPrefersDark = window.matchMedia("(prefers-color-scheme: dark)").matches;
      setMode(systemPrefersDark ? "dark" : "light");
    }
  }, []);

  const toggleColorMode = () => {
    const newMode = mode === "dark" ? "light" : "dark";
    setMode(newMode);
    localStorage.setItem("themeMode", newMode); // Save the selected mode to localStorage
  };

  const toggleCustomTheme = () => {
    setShowCustomTheme((prev) => !prev);
  };

  // Handle plan selection and scroll to Hero section
  const handleSelectPlan = (planTitle, billingCycle) => {
    scroller.scrollTo("hero", {
      duration: 800,
      delay: 0,
      smooth: "easeInOut",
    });
    const formattedPlan = `${planTitle} (${billingCycle})`;
    setSelectedPlan(formattedPlan); // Set the selected plan in state
  };

  return (
    <ThemeProvider theme={showCustomTheme ? MPTheme : defaultTheme}>
      <CssBaseline enableColorScheme />
      <AppAppBar />
      <Hero selectedPlan={selectedPlan} />
      <LogoCollection />
      <div style={{ backgroundColor: "" }}>
        {/*<LogoCollection />*/}
        <Features />

        <Highlights />
        <Divider />
        <Pricing onSelectPlan={handleSelectPlan} />
        <Divider />
        <FAQ />
        <Testimonials />
        <Divider />
      </div>
      <div style={{ backgroundColor: "#181818" }}>
        <Footer />
      </div>
    </ThemeProvider>
  );
}
