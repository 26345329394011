import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

// Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCQKvWswFvEjXQfqFZi1nC1eHgNd8y8TGc",
  authDomain: "branch-d2916.firebaseapp.com",
  projectId: "branch-d2916",
  storageBucket: "branch-d2916.appspot.com",
  messagingSenderId: "209391176185",
  appId: "1:209391176185:web:b621dbf9d6e7fa93eb1528",
  measurementId: "G-VC2XMLTQW5",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

export { auth, db, app };
