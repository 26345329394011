import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Drawer from "@mui/material/Drawer";
import MenuIcon from "@mui/icons-material/Menu";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import { motion, AnimatePresence } from "framer-motion";
import { Typography } from "@mui/material";
import { ArrowRight, ChevronRightOutlined, Support } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  flexShrink: 0,
  backdropFilter: "blur(24px)",
  border: "1px solid",
  borderColor: theme.palette.divider,
  backgroundColor: "#181818",
  padding: "0px 20px",
  height: "50px",
  zIndex: 150,
}));

const SubMenuWrapper = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "100%",
  left: 0,
  padding: "40px 30px",
  backgroundColor: "rgba(255, 255, 255,.8)",
  borderRadius: 8,
  overflow: "hidden",
  zIndex: 100,
  display: "grid",
  gridTemplateColumns: "1fr 1fr",
  gap: theme.spacing(3),
  boxShadow: "hsla(120, 10%, 15%, .4) 0px 20px 50px -10px, hsla(120, 25%, 10%, .15) 0px 150px 70px -70px",
  mixBlendMode: "multiply",
  backdropFilter: "blur(15px)",
}));

const SubMenuItem = styled(MenuItem)(({ theme }) => ({
  padding: theme.spacing(1, 2),
  fontSize: "0.875rem",
  color: theme.palette.text.primary,

  "& svg": {
    marginRight: theme.spacing(1),
    color: "rgba(0,0,0,0.5)",
    transition: "color 0.3s ease",
  },

  "&:hover": {
    backgroundColor: "rgba(0,0,0,0)",
    color: theme.palette.text.primary,
    "& svg": {
      color: "black",
    },
  },
}));

const MenuButton = styled(Button)(({ theme }) => ({
  textTransform: "none",
  fontWeight: "500",
  height: "50px",
  fontSize: ".8rem",
  padding: theme.spacing(0, 2),
}));

export default function AppAppBar() {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [openSubmenu, setOpenSubmenu] = useState(null); // Manage which submenu is open

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
    if (!newOpen) setOpenSubmenu(null); // Close submenu when drawer closes
  };

  const handleSubmenuOpen = (label) => {
    setOpenSubmenu(label); // Open submenu for the specific label
  };

  const handleSubmenuClose = () => {
    setOpenSubmenu(null); // Close submenu
  };

  const handleScroll = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  const menuItems = [
    {
      label: "Products",
      id: "products",
      submenu: {
        title: "Resources",
        menu: [
          { label: "Pre-built Checkout", icon: <Support />, path: "/checkout", id: "doc1" },
          { label: "Libraries and SDKs", icon: <Support />, path: "/sdks", id: "doc2" },
          { label: "App Integrations", icon: <Support />, path: "/integrations", id: "doc3" },
        ],
      },
    },
    {
      label: "Solutions",
      id: "solutions",
      submenu: {
        title: "By use case",
        menu: [
          { label: "Ordering", icon: <Support />, path: "/solutions/ordering", id: "doc1" },
          { label: "Libraries and SDKs", icon: <Support />, path: "/sdks", id: "doc2" },
          { label: "App Integrations", icon: <Support />, path: "/integrations", id: "doc3" },
        ],
      },
    },
    { label: "Developers", id: "pricing" },
    { label: "Resources", id: "compare-costs" },
    { label: "Pricing", id: "faq" },
    {
      label: "Blog",
      submenu: {
        title: "Resources",
        menu: [
          { label: "Pre-built Checkout", icon: <Support />, path: "/checkout", id: "doc1" },
          { label: "Libraries and SDKs", icon: <Support />, path: "/sdks", id: "doc2" },
          { label: "App Integrations", icon: <Support />, path: "/integrations", id: "doc3" },
          { label: "Code Samples", icon: <Support />, path: "/code-samples", id: "doc4" },
          { label: "Accept Online Payments", path: "/online-payments", id: "doc5" },
          { label: "Manage Subscriptions", path: "/subscriptions", id: "doc6" },
          { label: "Send Payments", path: "/send-payments", id: "doc7" },
          { label: "Set up In-Person Payments", path: "/in-person-payments", id: "doc8" },
        ],
      },
    },
  ];

  const currentSubmenu = menuItems.find((item) => item.label === openSubmenu); // Safely get the current submenu

  return (
    <AppBar
      position="fixed"
      sx={{
        boxShadow: 0,
        bgcolor: "#181818",
        backgroundImage: "none",
        mt: 0,
        width: "100%",
        zIndex: 150,
        cursor: "pointer",
      }}
    >
      <StyledToolbar variant="dense" disableGutters>
        <Box sx={{ flexGrow: 1, display: "flex", alignItems: "center", px: 0, height: 50 }} onClick={() => navigate("/")}>
          <p
            style={{
              marginRight: 50,
              color: "#dae121",
              fontFamily: "Mogra",
              fontSize: 24,
              height: 50,
              alignContent: "center",
              paddingTop: 4,
            }}
          >
            MEALMATE
          </p>

          {/* Desktop Menu */}
          <Box sx={{ display: { xs: "none", md: "flex" } }}>
            {menuItems.map((menuItem) => (
              <Box
                key={menuItem.label}
                onMouseEnter={() => menuItem.submenu && handleSubmenuOpen(menuItem.label)}
                onMouseLeave={() => menuItem.submenu && handleSubmenuClose()}
                sx={{ position: "relative", display: "inline-block" }}
              >
                <MenuButton variant="text" color="inherit" size="small" onClick={() => handleScroll(menuItem.id)}>
                  {menuItem.label}
                </MenuButton>

                {menuItem.submenu && openSubmenu === menuItem.label && (
                  <AnimatePresence>
                    <motion.div initial={{ opacity: 0, y: -10 }} animate={{ opacity: 1, y: 0 }} exit={{ opacity: 0, y: 0 }} transition={{ duration: 0.35 }}>
                      <SubMenuWrapper>
                        <Typography variant="h6" sx={{ gridColumn: "1 / -1", color: "black" }}>
                          {menuItem.submenu.title}
                        </Typography>
                        {menuItem.submenu.menu.map((item) => (
                          <SubMenuItem key={item.id} onClick={() => handleScroll(item.id)}>
                            {item.icon && item.icon}
                            {item.label}
                          </SubMenuItem>
                        ))}
                      </SubMenuWrapper>
                    </motion.div>
                  </AnimatePresence>
                )}
              </Box>
            ))}
          </Box>
        </Box>

        {/* Sign in/Sign up Buttons for Desktop */}
        <Box sx={{ display: { xs: "none", md: "flex" }, gap: 1, alignItems: "center" }}>
          <Button variant="text" color="secondary" size="small">
            Sign in
          </Button>
          <Button variant="contained" color="primary" size="small">
            Sign up
          </Button>
        </Box>

        {/* Mobile Drawer */}
        <Box sx={{ display: { xs: "flex", md: "none" } }}>
          <IconButton aria-label="Menu button" onClick={toggleDrawer(true)} style={{ color: "white" }}>
            <MenuIcon />
          </IconButton>
          <Drawer anchor="top" open={open} onClose={toggleDrawer(false)}>
            <Box sx={{ p: 2, backgroundColor: "background.default" }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <IconButton onClick={toggleDrawer(false)}>
                  <CloseRoundedIcon />
                </IconButton>
              </Box>
              <Divider sx={{ my: 3 }} />

              {/* Main Menu for Mobile */}
              <motion.div initial={openSubmenu ? { x: 0 } : { x: 0 }} animate={openSubmenu ? { x: "-100%" } : { x: "0" }} transition={{ duration: 0.4 }}>
                {menuItems.map((menuItem) => (
                  <MenuItem
                    key={menuItem.label}
                    onClick={() => (menuItem.submenu ? handleSubmenuOpen(menuItem.label) : handleScroll(menuItem.id))}
                    sx={{ justifyContent: "space-between" }}
                  >
                    {menuItem.label}
                    {menuItem.submenu && <ChevronRightOutlined sx={{ fontSize: 24, color: "gray" }} />}
                  </MenuItem>
                ))}
              </motion.div>

              {/* Submenu for Mobile */}
              {currentSubmenu && (
                <motion.div
                  initial={{ x: "100%" }}
                  animate={openSubmenu ? { x: "0%" } : { x: "100%" }}
                  transition={{ duration: 0.4 }}
                  style={{ position: "absolute", width: "100%", top: 0 }}
                >
                  <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                    <IconButton onClick={handleSubmenuClose}>
                      <ArrowBackIcon />
                    </IconButton>
                    <Typography>{currentSubmenu.submenu.title}</Typography>
                  </Box>
                  <Divider sx={{ my: 3 }} />
                  {currentSubmenu.submenu.menu.map((item) => (
                    <MenuItem key={item.id} onClick={() => handleScroll(item.id)}>
                      {item.icon && item.icon}
                      {item.label}
                    </MenuItem>
                  ))}
                </motion.div>
              )}

              {/* Sign in/Sign up for Mobile */}
              <MenuItem>
                <Button color="primary" variant="contained" fullWidth>
                  Sign up
                </Button>
              </MenuItem>
              <MenuItem>
                <Button color="primary" variant="outlined" fullWidth>
                  Sign in
                </Button>
              </MenuItem>
            </Box>
          </Drawer>
        </Box>
      </StyledToolbar>
    </AppBar>
  );
}
