import React, { useState, useEffect, useRef } from "react";
import { Box, Container, Typography, Button, TextField, CircularProgress, Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import { useInView } from "react-intersection-observer";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import AppAppBar from "../../elements/header/AppAppBar";

import getMPTheme from "../../theme/getMPTheme";
// Import your SVG components if needed
//import FeatureSvg_01 from "./features/FeatureSvg_01";
// import FeatureSvg_02 from "../Features/FeatureSvg_02";
// import FeatureSvg_03 from "./Features/FeatureSvg_03";
// import FeatureSvg_04 from "./Features/FeatureSvg_04";
import { ReactComponent as AppTestSvg_01 } from "../../assets/svg/get_started/app_test_mode.svg";

// Import Firebase modules
import { getAuth, createUserWithEmailAndPassword, sendEmailVerification } from "firebase/auth";
import { doc, setDoc, serverTimestamp, onSnapshot } from "firebase/firestore";
import { useFirestore } from "../../firebase/FirestoreContext"; // Use your Firestore context
import { app } from "../../firebase/firebaseConfig"; // Your Firebase configuration file

const items = [
  {
    title: "Step 1: Create your account",
    description:
      "Create your account using email, Gmail, or Apple ID. After creating the account, confirm your email address by clicking the link sent to your email.",
    svg: 1,
  },
  {
    title: "Step 2: Install and Login to Mobile App",
    description: "Install the mobile app and log in with the same account you created. We will wait for your app registration to complete.",
    svg: 2,
  },
  {
    title: "Step 3: Provide Your Restaurant Information",
    description:
      "Fill out the form with your restaurant name, address, and your personal details. We'll create your Paydeski account based on this information.",
    svg: 3,
  },
  {
    title: "Step 4: Select Your Plan",
    description: "Choose one of the available options to proceed. After selection, we'll finalize the setup through an API call.",
    svg: 4,
  },
];

export default function Features() {
  const [mode, setMode] = useState("light");
  const [showCustomTheme, setShowCustomTheme] = useState(true);
  const MPTheme = createTheme(getMPTheme(mode));
  const defaultTheme = createTheme({ palette: { mode } });

  // Existing code for features
  const [activeSvg, setActiveSvg] = useState(1);
  const [opacity, setOpacity] = useState(1);
  const [scale, setScale] = useState(1); // State to handle zoom effect
  const refs = useRef([]);

  // Declare useInView hooks outside of the loop
  const { ref: ref1, inView: inView1 } = useInView({ threshold: 1 });
  const { ref: ref2, inView: inView2 } = useInView({ threshold: 0.6 });
  const { ref: ref3, inView: inView3 } = useInView({ threshold: 0.6 });
  const { ref: ref4, inView: inView4 } = useInView({ threshold: 0.6 });

  // Array of refs created for easy mapping
  const inViewRefs = [ref1, ref2, ref3, ref4];

  const [currentStep, setCurrentStep] = useState(1); // Control the current step
  const [completedSteps, setCompletedSteps] = useState([]);

  useEffect(() => {
    if (inView1) {
      switchSvg(1);
      setCurrentStep(1);
    } else if (inView2) {
      switchSvg(2);
      setCurrentStep(2);
    } else if (inView3) {
      switchSvg(3);
      setCurrentStep(3);
    } else if (inView4) {
      switchSvg(4);
      setCurrentStep(4);
    }
  }, [inView1, inView2, inView3, inView4]);

  const switchSvg = (newSvg) => {
    if (newSvg === activeSvg) return;

    setTimeout(() => {
      setOpacity(0);
      setScale(1.15);
      // Change the SVG component
      setTimeout(() => {
        setActiveSvg(newSvg);
        setOpacity(1);
        setScale(1);
      }, 220);
    }, 0);
  };

  // Registration process state variables
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailSent, setEmailSent] = useState(false);
  const [loading, setLoading] = useState(false);

  // Step 3 variables
  const [restaurantName, setRestaurantName] = useState("");
  const [address, setAddress] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  // Step 4 variables
  const [selectedOption, setSelectedOption] = useState("");

  // Firebase instances
  const auth = getAuth(app);
  const firestore = useFirestore();

  const handleSignUp = async () => {
    setLoading(true);
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      // Send email verification
      await sendEmailVerification(user);
      setEmailSent(true);

      // Create user document in Firestore
      await setDoc(doc(firestore, "Users", "UsersList", "DataBase", user.uid), {
        ID: user.uid,
        isAnonymous: user.isAnonymous,
        metaData: {
          creationTime: user.metadata.creationTime,
          lastSignInTime: user.metadata.lastSignInTime,
        },
        registerProvider: user.providerData[0]?.providerId || "email",
        language: "en",
        timeFormat: 12,
        unit: "metric",
        registrationTime: serverTimestamp(),
        appRegistrationTime: null,
      });
    } catch (error) {
      console.error("Error creating account:", error);
    }
    setLoading(false);
  };

  const checkEmailVerification = async () => {
    setLoading(true);
    try {
      await auth.currentUser.reload();
      const user = auth.currentUser;
      if (user.emailVerified) {
        setCompletedSteps((prev) => [...prev, 1]);
        // Move to next step
      } else {
        alert("Email not verified yet.");
      }
    } catch (error) {
      console.error("Error checking email verification:", error);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (completedSteps.includes(1)) {
      const user = auth.currentUser;
      if (user) {
        const docRef = doc(firestore, "Users", "UsersList", "DataBase", user.uid);
        const unsubscribe = onSnapshot(docRef, (docSnap) => {
          if (docSnap.exists()) {
            const data = docSnap.data();
            if (data.appRegistrationTime) {
              setCompletedSteps((prev) => [...prev, 2]);
              unsubscribe();
            }
          }
        });
      }
    }
  }, [completedSteps, firestore, auth.currentUser]);

  const handleSubmitStep3 = async () => {
    setLoading(true);
    try {
      // Call API to create paydeski
      const response = await fetch("API_ENDPOINT", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          restaurantName,
          address,
          firstName,
          lastName,
        }),
      });
      const data = await response.json();

      // From response, create doc in _CMS/Admins/DataBase/{userId}/Accounts/{Id}
      const user = auth.currentUser;
      const accountId = data.accountId; // Adjust based on your API response

      await setDoc(doc(firestore, "_CMS", "Admins", "DataBase", user.uid, "Accounts", accountId), data);

      // Set name and surname in _CMS/Admins/DataBase/{userId}
      await setDoc(
        doc(firestore, "_CMS", "Admins", "DataBase", user.uid),
        {
          firstName,
          lastName,
        },
        { merge: true }
      );

      setCompletedSteps((prev) => [...prev, 3]);
    } catch (error) {
      console.error("Error in Step 3:", error);
    }
    setLoading(false);
  };

  const handleSubmitStep4 = async () => {
    setLoading(true);
    try {
      // Call API with selectedOption
      const response = await fetch("API_ENDPOINT_FOR_STEP4", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          selectedOption,
        }),
      });
      const data = await response.json();

      // Handle response as needed

      setCompletedSteps((prev) => [...prev, 4]); // Registration process complete
    } catch (error) {
      console.error("Error in Step 4:", error);
    }
    setLoading(false);
  };

  return (
    <>
      <ThemeProvider theme={showCustomTheme ? MPTheme : defaultTheme}>
        <AppAppBar />
        <Box sx={{ mb: { position: "relative" } }}>
          <Container
            id="features"
            sx={{
              display: "flex",
              maxWidth: "lg",
              padding: "0px !important",
              borderLeft: "1px solid rgba(0, 0, 0, 0.07)",
              borderRight: "1px solid rgba(0, 0, 0, 0.07)",
              padding: "100px 0 50px 0",
            }}
          >
            <div style={{ display: "flex", flex: 1, flexDirection: "column", padding: "30px 0" }}>
              <h1 style={{ color: "rgba(24,24,24,.8)", zIndex: -100 }}>Title, Description, and Image</h1>
              <div style={{}} className="h1-sub">
                Accept payments online, in person, and around the world with a payments solution built for any business – from scaling startups to global
                enterprises.
              </div>
            </div>
            <div style={{ display: "flex", flex: 1, flexDirection: "column" }}>
              <div
                style={{
                  height: "100%",
                  borderRadius: "10px",
                  display: "flex",
                  flexDirection: "column",
                  padding: "30px 0",
                  backgroundColor: "white",
                }}
                class="shadow_medium"
              ></div>
            </div>
          </Container>
        </Box>
        <Box
          id="highlights"
          sx={{
            position: "relative",
            backgroundColor: "#fff",
            padding: "0",
            borderTop: ".5px dashed rgba(0, 0, 0, 0.15)",
            backgroundColor: "#fafafa",
          }}
        >
          <Container
            id="features"
            sx={{
              display: "flex",
              maxWidth: "lg",
              padding: "0px !important",
              borderLeft: "1px solid rgba(0, 0, 0, 0.07)",
              borderRight: "1px solid rgba(0, 0, 0, 0.07)",
            }}
          >
            {/* Scrollable Text Section */}
            <Box
              sx={{
                width: { xs: "100%", md: "50%" },
              }}
            >
              {items.map((item, index) => (
                <Box
                  key={index}
                  ref={inViewRefs[index]} // Assigning the correct ref to each item
                  sx={{
                    minHeight: "50vh",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    padding: "20px",
                  }}
                >
                  <Typography
                    variant="h3"
                    sx={{
                      fontWeight: "500",
                      mb: 2,
                      fontSize: "1.8rem",
                      color: currentStep === index + 1 ? "text.primary" : "text.disabled",
                    }}
                  >
                    {item.title}
                  </Typography>
                  <Typography variant="body1" style={{ lineHeight: "28px", fontSize: "1rem", opacity: 0.7 }}>
                    {item.description}
                  </Typography>
                  {/* Render the form or content based on the current step */}
                  {currentStep === index + 1 && (
                    <Box sx={{ mt: 2 }}>
                      {/* Step-specific content */}
                      {currentStep === 1 && (
                        <Box>
                          {!emailSent ? (
                            <Box>
                              <TextField label="Email" value={email} onChange={(e) => setEmail(e.target.value)} sx={{ mb: 2 }} fullWidth />
                              <TextField
                                label="Password"
                                type="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                sx={{ mb: 2 }}
                                fullWidth
                              />
                              <Button variant="contained" onClick={handleSignUp} disabled={loading}>
                                {loading ? <CircularProgress size={24} /> : "Sign Up"}
                              </Button>
                            </Box>
                          ) : (
                            <Box>
                              <Typography sx={{ mb: 2 }}>Verification email sent to {email}. Please verify your email and click the button below.</Typography>
                              <Button variant="contained" onClick={checkEmailVerification} disabled={loading}>
                                {loading ? <CircularProgress size={24} /> : "I have verified my email"}
                              </Button>
                            </Box>
                          )}
                        </Box>
                      )}
                      {currentStep === 2 && (
                        <Box>
                          <Typography sx={{ mb: 2 }}>Please install the mobile app and log in with the same account.</Typography>
                          <Typography>Waiting for app registration...</Typography>
                        </Box>
                      )}
                      {currentStep === 3 && (
                        <Box>
                          <TextField
                            label="Restaurant Name"
                            value={restaurantName}
                            onChange={(e) => setRestaurantName(e.target.value)}
                            sx={{ mb: 2 }}
                            fullWidth
                          />
                          <TextField label="Address" value={address} onChange={(e) => setAddress(e.target.value)} sx={{ mb: 2 }} fullWidth />
                          <TextField label="First Name" value={firstName} onChange={(e) => setFirstName(e.target.value)} sx={{ mb: 2 }} fullWidth />
                          <TextField label="Last Name" value={lastName} onChange={(e) => setLastName(e.target.value)} sx={{ mb: 2 }} fullWidth />
                          <Button variant="contained" onClick={handleSubmitStep3} disabled={loading}>
                            {loading ? <CircularProgress size={24} /> : "Submit"}
                          </Button>
                        </Box>
                      )}
                      {currentStep === 4 && (
                        <Box>
                          <FormControl fullWidth sx={{ mb: 2 }}>
                            <InputLabel id="select-option-label">Select an Option</InputLabel>
                            <Select
                              labelId="select-option-label"
                              value={selectedOption}
                              label="Select an Option"
                              onChange={(e) => setSelectedOption(e.target.value)}
                            >
                              <MenuItem value={1}>Option 1</MenuItem>
                              <MenuItem value={2}>Option 2</MenuItem>
                              <MenuItem value={3}>Option 3</MenuItem>
                            </Select>
                          </FormControl>
                          <Button variant="contained" onClick={handleSubmitStep4} disabled={loading || !selectedOption}>
                            {loading ? <CircularProgress size={24} /> : "Submit"}
                          </Button>
                        </Box>
                      )}
                      {currentStep > 4 && (
                        <Box>
                          <Typography>Registration process complete!</Typography>
                        </Box>
                      )}
                    </Box>
                  )}
                </Box>
              ))}
            </Box>

            {/* Fixed Image Section */}
            <Box
              sx={{
                width: "50%",
                display: { xs: "none", md: "block" },
                position: "sticky",
                top: 0,
                alignItems: "flex-start",
                justifyContent: "flex-start",
                padding: "20px ",
              }}
            >
              <Box
                sx={{
                  aspectRatio: 1,
                  width: "100%",
                  transition: "opacity .3s ease-in-out, transform .4s ease-in-out",
                  position: "sticky",
                  top: "25%",
                  border: "1px dashed rgba(0, 0, 0, 0.07)",
                  padding: "10px",
                  opacity: opacity,
                  transform: `scale(${scale})`, // Applying zoom effect
                  display: "flex",
                }}
              >
                {activeSvg === 1 && <Box>SVG 2</Box>}

                {activeSvg === 2 && <AppTestSvg_01 width="10px" height="15px" style={{ display: "flex", width: "100%", height: "100%" }} />}
                {/*activeSvg === 2 && <FeatureSvg_02 />*/}
                {/*activeSvg === 3 && <FeatureSvg_03 />*/}
                {/*activeSvg === 4 && <FeatureSvg_04 />*/}
              </Box>
            </Box>
          </Container>
        </Box>
      </ThemeProvider>
    </>
  );
}
