import React, { useEffect } from "react";
import { BorderLeft } from "@mui/icons-material";
import zIndex from "@mui/material/styles/zIndex";
import { fontFamily, letterSpacing, lineHeight } from "@mui/system";
import { useNavigate } from "react-router-dom";

import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";

export default function Hero({ selectedPlan }) {
  const HeroText = `Unlock a new revenue stream`;
  const navigate = useNavigate();

  return (
    <Container
      id="features"
      sx={{
        py: {},
        maxWidth: "lg",
        borderLeft: "1px solid rgba(0, 0, 0, 0.07)",
        borderRight: "1px solid rgba(0, 0, 0, 0.07)",
        padding: "0 10px 50px 10px !important",
      }}
    >
      <Box sx={{ textAlign: "center", mb: { position: "relative" } }}>
        <div style={{ ...styles.heroContainer }}>
          <div
            style={{
              flex: 0.8,
              position: "relative",
              height: "100%",
              alignContent: "flex-end",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              alignContaint: "center",
            }}
          >
            {/* Text with mix-blend-mode overlay */}
            <div
              style={{
                position: "relative", // Position this absolutely relative to the parent
                left: 0,
                right: 0,
                top: 0,
                zIndex: -50,
              }}
            >
              <h1 style={{ ...styles.hero_title }}>{HeroText}</h1>
            </div>
            <div style={{ ...styles.hero_description }} className="h1-sub">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec auctor, nunc nec ultricies ultricies, nunc
            </div>
            <div style={{ textAlign: "left" }}>
              <Button style={{ background: "#181818", color: "white", borderRadius: 100, fontSize: ".75rem", padding: "8px 24px", fontWeight: "600" }}>
                Download App!
              </Button>
              <Button
                style={{ color: "black", borderRadius: 100, fontSize: ".75rem", padding: "8px 24px", fontWeight: "600", marginLeft: "10px" }}
                variant="outlined"
                onClick={() => navigate(`/create-account`)}
              >
                Become a Partner <i className="fas fa-arrow-right"></i>
              </Button>
            </div>
          </div>
          <div style={{ ...styles.hero_image, flex: 1 }} className="hideOnMobile"></div>
        </div>
      </Box>
    </Container>
  );
}

// style
const styles = {
  heroContainer: {
    height: "600px",
    alignContaint: "center",
    justifyContent: "center",
    display: "flex",
    flexDirection: "row",
  },

  hero_image: {
    flex: 1,

    backgroundImage: `url(/mealmate_hero.png)`,
    backgroundSize: "auto 100%",
    zIndex: 50,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center 0px",
    // if with is less than 600px than hide div (responsive)
    "@media (max-width: 600px)": {
      display: "none",
    },
  },
};
